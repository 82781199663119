import Exit from "../../assets/Exit";
import Modal from "../GlobalModal/GlobalModal";
import styles from "./ModalLeaveSite.module.scss";

export default function ModalLeaveSite(props) {
  return (
    <Modal>
      <h3>Attention ! En cliquant sur ce lien, <br/><span className={styles.blue}> vous quittez notre site.</span></h3>
      <p>Le site web que vous avez demandé à consulter peut contenir des informations sur des produits ou des utilisations de produits qui ne sont pas validées en France. D'autre part, les mentions légales du site hepatitec-info.fr ne s’appliqueront plus. Il faudra vous référer aux mentions légales du site en question.</p>
      <div className={styles.twoColumn}>
        <button onClick={props.close} className={styles.blueButton}>Annuler</button>
        <a href={props.url} target="_blank" rel="noreferrer" className={styles.greyButton}>Continuer</a>
      </div>
      <div className={styles.exit} onClick={props.close}>
        <Exit />
      </div>
    </Modal>
  );
}