import styles from "./AboutCare.module.scss";
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import { Link, NavLink } from "react-router-dom";
import Location from "../../assets/Location";
import QuestionMark from "../../assets/QuestionMark";
import SignsAndSymptoms from "../../assets/SignsAndSymptoms";
import HCVChiffresCles from "../../assets/HCV-chiffres-cles.webp"
import { Helmet } from "react-helmet";

const AboutSymptoms = (type) => {

  function appendSegment(type, value) {
    if (type.type === "seg-2") {
      return "seg2_3_" + value;
    } else {
      return "seg1_" + value;
    }
  }

  return (
    <>
      <Helmet>
        <title>About Hep C - Hep C signs and symptoms</title>
        <meta
          name="description"
          content="They're not always obvious"
        />
      </Helmet>
      <section className={styles.headerBanner}>
        <MainWrapper>
          <h1>A propos de <span className={styles.blue}>l'hépatite&nbsp;C</span></h1>
          <div className={styles.threeColumn}>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/">Hépatite C</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/symptoms">Symptômes</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/diagnosis">Diagnostic</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/prise-en-charge">Prise en charge</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/faqs">Questions</NavLink>
            </div>
          </div>
          <div className={styles.icon}>
            <SignsAndSymptoms />
          </div>
        </MainWrapper>
      </section>
      <section>
        <MainWrapper size="8">
          <h2>Comment l’hépatite&nbsp;C <span className={styles.blue}>est-elle prise en charge ?</span></h2>
          <p>Les traitements de l’hépatite C chronique sont les antiviraux à action directe, disponibles en pharmacie de ville ou à l’hôpital, ils : </p>
          <img src={HCVChiffresCles} alt="HCV chiffres clés"></img>
          <p>Ils sont accessibles indépendamment du stade d’avancement de la maladie, du niveau de consommation d’alcool et/ou de drogue, et du niveau de précarité. <sup>(12)(13)(14)</sup></p>
        </MainWrapper>
      </section>
      <section className={styles.references}>
        <MainWrapper>
          <p>Références :</p>
          <p>
          6. OMS. Hépatite C<br></br>
          9. HAS. Hépatite C : prise en charge simplifiée chez l’adulte. Fiche mémo<br></br>
          10. Vidal. Hépatite C<br></br>
          11. SNFGE. Hépatite C. Janvier 2019<br></br>
          12. Ameli. Le traitement de l’hépatite C<br></br>
          13. AFEF. Recommandation sur la prise en charge de l’hépatite&nbsp;C virale<br></br>
          14. Dhumeaux D. Prise en charge thérapeutique et suivi de l’ensemble des personnes infectées par le virus de l’hépatite C. Rapport de recommandation 2016
          </p>
        </MainWrapper>
      </section>
    </>
  );
};

export default AboutSymptoms;
