import React, { useState } from "react"
import styles from "./Header.module.scss";
import MainWrapper from "../MainWrapper/MainWrapper";
import { NavLink } from "react-router-dom";
import Location from "../../assets/Location";
import CloseWhite from "../../assets/CloseWhite";

const Header = (type) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  const closeMenu = () => {
    setMenuOpen(false);
  }

  function sethome(type) {
    if (type.type == "seg-1") {
      console.log("/segment-1");
      return "/segment-1";
    }
    else {
      console.log("/segment-2&3");
      return "/segment-2&3";
    }
  }

  function stateTracking(type, item) {
    if (type.type === "seg-2") {
      return "seg2_3_finddoctor_" + item;
    } else {
      return "seg1_finddoctor_" + item;
    }
  }

  const mobileStyle = menuOpen ? styles.menuOpen : '';

  return (
    <>
      <header className={`${styles.header} ${mobileStyle}`}>
        <MainWrapper>
          <nav>
            <div className={styles.leftAlign}>
              <ul className={styles.desktopMenu}>
                <li><NavLink className={({ isActive }) => isActive ? styles.active : undefined} to={sethome(type)}>Accueil</NavLink></li>
                <li><NavLink className={({ isActive }) => isActive ? styles.active : undefined} to="/about">hépatite&nbsp;C</NavLink></li>
                <li><NavLink className={({ isActive }) => isActive ? styles.active : undefined} to="/stories">Leurs histoires</NavLink></li>
                <li><NavLink className={({ isActive }) => isActive ? styles.active : undefined} to="/liens-utiles">Liens utiles</NavLink></li>
              </ul>
              <div className={styles.mobileMenu}>
                <button onClick={toggleMenu} className={`${styles.hamburger} ${mobileStyle}`}>
                  <span className={styles.hamburgerBox}>
                    <span className={styles.hamburgerInner}></span>
                  </span>
                </button>
              </div>
            </div>
            <div className={styles.rightAlign}>
              <ul className={styles.desktopMenu}>
                <li><Location /></li>
                <li><NavLink className={({ isActive }) => isActive ? styles.active : undefined} to="/doctor" id={stateTracking(type, "navbutton")}>Où se faire dépister</NavLink></li>
              </ul>
            </div>
          </nav>
        </MainWrapper>
      </header>
      <div className={`${styles.mobileNavigation} ${mobileStyle}`}>
        <div className={styles.close} onClick={closeMenu}>
          <CloseWhite />
        </div>
        <MainWrapper>
          <ul>
            <li><NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} onClick={closeMenu} to={sethome(type)}>Accueil</NavLink></li>
            <li><NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} onClick={closeMenu} to="/about">hépatite&nbsp;C</NavLink></li>
            <li><NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} onClick={closeMenu} to="/stories">Leurs histoires</NavLink></li>
            <li><NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} onClick={closeMenu} to="/liens-utiles">Liens utiles</NavLink></li>
          </ul>
        </MainWrapper>
      </div>
    </>
  );
};

export default Header;
