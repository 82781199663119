const CloseWhite = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 40C31.4934 40 40 31.4934 40 21C40 10.5066 31.4934 2 21 2C10.5066 2 2 10.5066 2 21C2 31.4934 10.5066 40 21 40Z" fill="#b65e29" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21 4C11.6112 4 4 11.6112 4 21C4 30.3888 11.6112 38 21 38C30.3888 38 38 30.3888 38 21C38 11.6112 30.3888 4 21 4ZM0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21Z" fill="white" />
      <path d="M21.1421 23.8287L26.7988 29.4855L29.6272 26.657L23.9705 21.0003L29.6275 15.3433L26.7991 12.5148L21.1421 18.1719L15.4851 12.5149L12.6567 15.3433L18.3137 21.0003L12.657 26.657L15.4854 29.4854L21.1421 23.8287Z" fill="white" />
    </svg>
  );
};

export default CloseWhite;
