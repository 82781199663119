import React from "react"
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { useState } from "react";
import styles from "./Carousel.module.scss"
import Arrow from "../Arrow/Arrow";
import CarouselSlide from "../CarouselSlide/CarouselSlide";
import Kiah from "../../assets/kiah.webp"
import Kelly from "../../assets/kelly.webp"
import Bill from "../../assets/bill.webp"

const sliderContent = [
  {
    image: Kiah,
    name: "Kiah",
    accountId: "1029485116001",
    videoId: "6312524973112"
  },
  {
    image: Kelly,
    name: "Kelly",
    accountId: "1029485116001",
    videoId: "6312524185112"
  },
  {
    image: Bill,
    name: "Bill",
    accountId: "1029485116001",
    videoId: "6312525566112"
  },
  {
    image: Kiah,
    name: "Kiah",
    accountId: "1029485116001",
    videoId: "6312524973112"
  },
  {
    image: Kelly,
    name: "Kelly",
    accountId: "1029485116001",
    videoId: "6312524185112"
  },
  {
    image: Bill,
    name: "Bill",
    accountId: "1029485116001",
    videoId: "6312525566112"
  },
];

const Carousel = (type, location) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: 3,
      origin: 'center',
    },
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    breakpoints: {
      '(max-width: 780px)': {
        slides: {
          perView: 1,
          origin: 'center',
        },
      },
    },
  })

  const updateOverlay = value => {
    setShowOverlay(value);
  };

  return (
    <>
      <div className={styles.navigationWrapper}>
        <div ref={sliderRef} className={`${showOverlay ? styles.disableOverflow : ""} keen-slider`}>

          {sliderContent.map((slide, index) => (
            <CarouselSlide
              key={index}
              slide={slide}
              sliderLength={5}
              index={index}
              instanceRef={instanceRef}
              currentSlide={currentSlide}
              showLabel={true}
              updateOverlay={updateOverlay}
              type={type}
              location={location}
            />
          ))}
        </div>
        {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev() || console.log("l")
              }
              disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            />
          </>
        )}
      </div>
    </>
  )
};


export default Carousel;

