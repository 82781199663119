const AccordionArrow = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M21 38C30.3888 38 38 30.3888 38 21C38 11.6112 30.3888 4 21 4C11.6112 4 4 11.6112 4 21C4 30.3888 11.6112 38 21 38ZM21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z" fill="#b65e29" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.5709 14.9435C19.7687 13.2909 22.2313 13.2909 23.4291 14.9435L30.5189 24.7258L27.2801 27.0732L21 18.4081L14.7199 27.0732L11.4811 24.7258L18.5709 14.9435Z" fill="#b65e29" />
    </svg>
  );
};

export default AccordionArrow;
