import React from "react"
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { useState } from "react";
import styles from "./CarouselThumbnail.module.scss"
import CarouselSlide from "../CarouselSlide/CarouselSlide";
import Kiah from "../../assets/kiah.webp"
import Kelly from "../../assets/kelly.webp"
import Bill from "../../assets/bill.webp"

const sliderContent = [
  {
    image: Kiah,
    name: "Kiah",
    accountId: "1029485116001",
    videoId: "6312524973112"
  },
  {
    image: Kelly,
    name: "Kelly",
    accountId: "1029485116001",
    videoId: "6312524185112"
  },
  {
    image: Bill,
    name: "Bill",
    accountId: "1029485116001",
    videoId: "6312525566112"
  },
  {
    image: Kiah,
    name: "Kiah",
    accountId: "1029485116001",
    videoId: "6312524973112"
  },
  {
    image: Kelly,
    name: "Kelly",
    accountId: "1029485116001",
    videoId: "6312524185112"
  },
  {
    image: Bill,
    name: "Bill",
    accountId: "1029485116001",
    videoId: "6312525566112"
  },
];

function ThumbnailPlugin(mainRef) {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active")
      })
    }
    function addActive(idx) {
      slider.slides[idx].classList.add("active")
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx)
        })
      })
    }

    slider.on("created", () => {
      if (!mainRef.current) return
      addActive(slider.track.details.rel)
      addClickEvents()
      mainRef.current.on("animationStarted", (main) => {
        removeActive()
        const next = main.animator.targetIdx || 0
        addActive(main.track.absToRel(next))
        slider.moveToIdx(next)
      })
    })
  }
}

const CarouselThumbnail = (type, location) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider({
    duration: 1000,
    slides: {
      perView: 1,
      origin: 'center',
    },
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true)
    },
    breakpoints: {
      '(max-width: 780px)': {
        slides: {
          perView: 1,
          origin: 'center',
        },
      },
    },
  })
  const [thumbnailRef] = useKeenSlider(
    {
      initial: 0,
      slides: {
        perView: 3,
        spacing: 0,
        origin: 'center',
      },
      loop: true,
      breakpoints: {
        '(max-width: 780px)': {
          slides: {
            perView: 2,
          },
        },
      },
    },
    [ThumbnailPlugin(instanceRef)]
  )

  const updateOverlay = value => {
   return null;
  };

  return (
    <>
      <div className={styles.navigationWrapper}>
        <div ref={sliderRef} className="keen-slider">
          {sliderContent.map((slide, index) => (
            <CarouselSlide
              key={index}
              slide={slide}
              sliderLength={5}
              index={index}
              instanceRef={instanceRef}
              currentSlide={currentSlide}
              thumbnail={false}
              showLabel={false}
              conjoined={true}
              isVideo={false}
              updateOverlay={updateOverlay}
              type={type}
              location={location}
            />
          ))}
        </div>
        <div ref={thumbnailRef} className={`${styles.thumbnail} keen-slider thumbnail`}>
          {sliderContent.map((slide, index) => (
            <CarouselSlide
              key={index}
              slide={slide}
              sliderLength={5}
              index={index}
              instanceRef={instanceRef}
              currentSlide={currentSlide}
              thumbnail={true}
              showLabel={true}
              conjoined={true}
              updateOverlay={updateOverlay}
              type={type}
              location={location}
            />
          ))}
        </div>
      </div>
    </>
  )
};


export default CarouselThumbnail;

