const SignsAndSymptoms = () => {
  return (
    <svg width="505" height="560" viewBox="0 0 505 560" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3_12)">
        <path d="M205.51 198.92C173.68 230.77 173.68 282.57 205.51 314.42C220.94 329.84 241.45 338.34 263.27 338.34C282.36 338.34 300.44 331.84 315 319.88V193.47C300.44 181.52 282.36 175.01 263.27 175.01C241.45 175.01 220.95 183.51 205.51 198.93V198.92ZM182.42 128.33H29.16C13.05 128.33 0 141.39 0 157.49C0 173.59 13.06 186.65 29.16 186.65H128.66C135.73 173.09 144.89 160.52 156.02 149.4C164.1 141.31 172.98 134.29 182.41 128.32L182.42 128.33ZM230.46 104.99C259.46 104.99 282.96 81.49 282.96 52.49C282.96 23.49 259.45 0 230.45 0C201.45 0 177.95 23.5 177.95 52.5C177.95 81.5 201.45 105 230.45 105L230.46 104.99ZM431.67 128.33H344.12C353.57 134.3 362.43 141.33 370.52 149.42C381.64 160.54 390.8 173.1 397.87 186.67H431.67C447.78 186.67 460.84 173.61 460.84 157.51C460.84 141.41 447.78 128.34 431.67 128.34V128.33ZM145.83 352.66V525C145.83 544.32 161.51 560 180.83 560C200.15 560 215.83 544.32 215.83 525V400.75C193.54 393.44 173.1 380.98 156.02 363.91C152.41 360.3 149.01 356.56 145.83 352.67V352.66ZM244.99 407.19V524.99C244.99 544.31 260.67 559.99 279.99 559.99C299.31 559.99 314.99 544.31 314.99 524.99V399.3C298.62 405.23 281.18 408.32 263.26 408.32C257.1 408.32 251 407.91 244.99 407.19V407.19Z" fill="white" />
        <path d="M494.26 438.16L387.19 331.09C383.9 327.79 380.09 325.33 376.05 323.63C388.02 303.56 394.54 280.65 394.54 256.67C394.54 221.61 380.89 188.65 356.09 163.86C331.3 139.07 298.34 125.42 263.29 125.42C228.24 125.42 195.27 139.07 170.48 163.86C145.69 188.65 132.03 221.61 132.03 256.67C132.03 291.73 145.68 324.68 170.48 349.47C195.27 374.26 228.23 387.92 263.29 387.92C287.25 387.92 310.16 381.4 330.24 369.44C331.95 373.49 334.41 377.29 337.7 380.59L444.77 487.66C451.6 494.5 460.56 497.91 469.52 497.91C478.48 497.91 487.43 494.5 494.27 487.66C507.93 474 507.93 451.84 494.27 438.17L494.26 438.16ZM191.09 328.85C171.81 309.57 161.19 283.93 161.19 256.67C161.19 229.41 171.81 203.77 191.09 184.48C210.37 165.21 236.01 154.58 263.28 154.58C290.55 154.58 316.17 165.2 335.46 184.48C354.73 203.76 365.36 229.4 365.36 256.67C365.36 283.94 354.74 309.57 335.46 328.85C316.17 348.12 290.54 358.75 263.28 358.75C236.01 358.75 210.37 348.13 191.09 328.85Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_3_12">
          <rect width="504.51" height="560" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SignsAndSymptoms;
