import styles from "./LegalDisclaimer.module.scss";
import React, { useState, useEffect } from "react"
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import Person from "../../assets/person_4.webp"
import PersonMobile from "../../assets/person_4_mobile.webp"
import LocationPin from "../../assets/LocationPin";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ModalService from '../../components/GlobalModal/ModalService';
import ModalLeaveSite from '../../components/ModalLeaveSite/ModalLeaveSite';

const LegalDisclaimer = (type) => {
  const [doesRelate, doesRelateChange] = useState(false);
  const [doesNotRelate, doesNotRelateChange] = useState(false);

  const doesRelateSelect = () => {
    doesRelateChange(true);
    doesNotRelateChange(false);
  }

  const doesNotRelateSelect = () => {
    doesRelateChange(false);
    doesNotRelateChange(true);
  }

  const location = useLocation();

  useEffect(() => {
    document.cookie = "homeModalSegmentA=true; expires=0; path=/;";
    document.cookie = "homeModalSegmentB=true; expires=0; path=/;";
    console.log('ignore-modal');
  });
  
  function appendSegment(type, value) {
    if (type.type === "seg-2") {
      return "seg2_3_" + value;
    } else {
      return "seg1_" + value;
    }
  }

  return (
    <>
      <Helmet>
        <title>About Hep C - Mentions légales </title>
        <meta
          name="description"
          content="Mentions légales"
        />
      </Helmet>
      <section className={styles.headerBanner}>
        <div className={styles.imageOverlay}>
          <MainWrapper>
            <div className={styles.position}>
              <img alt="person" src={Person} />
            </div>
          </MainWrapper>
        </div>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <img alt="person" className={styles.mobileHero} src={PersonMobile} />
              <div className={styles.brandBox}>
                <div className={styles.circle}>
                  <LocationPin />
                </div>
                <h1>Mentions légales</h1>
              </div>
            </div>
            <div className={styles.rightColumn}></div>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.alignLeft}>
        <MainWrapper size="8">
          <h2>Éditeur</h2>
          <p>Le site internet <a href="/">hepatitec-info.fr</a> est édité par AbbVie dont le siège social est situé 10 rue d’Arcueil – 94528 Rungis Cedex immatriculée au Registre du Commerce et des Sociétés de Créteil sous le numéro 750 775 660, et dont le capital social est de 133 449 467,40 euros.</p>
          <p>AbbVie peut être joint aux contacts suivants :</p>
          <p>Téléphone :&nbsp;<a href="tel:+33145601300">+33 (0)1 45 60 13 00</a><br/>Fax :&nbsp;<a href="tel:+33145601301">+33 (0) 1 45 60 13 01</a><br/>Web :&nbsp;<a onClick={(e) => { e.preventDefault(); ModalService.open(ModalLeaveSite, { url: "https://www.abbvie.fr/" }); }} href="https://www.abbvie.fr/" rel="noreferrer noopener" target="_blank">www.abbvie.fr</a></p>
          <p>Le directeur ou le codirecteur de la publication est Monsieur Stéphane Lassignardie.</p>
          <p>Le Site est hébergé par la société Amazon Web Services (AWS) :<br/>Amazon Web Services, Inc.<br/>P.O. Box 81226<br/>Seattle, WA 98108-1226<br/>https://aws.amazon.com</p>
          <p>Les règles édictées dans ce document lient toutes personnes physiques ou morales (ci-après désignées par “l’Utilisateur“) accédant au site internet&nbsp;<a href="/">hepatitec-info.fr</a>&nbsp;(ci-après désigné par le “Site“).</p>
          <p>L’accès au Site et son utilisation par l’Utilisateur sont soumis aux Conditions Générales et Spécifiques décrites ci-après, aux lois en vigueur concernant l’utilisation générale d’internet, aux dispositions réglementaires concernant l’industrie pharmaceutique et plus généralement au respect de toutes les lois applicables. AbbVie se réserve la possibilité, compte tenu des évolutions permanentes du réseau internet, de modifier, à tout moment et sans préavis, les conditions d’utilisation (ajout ou retrait) proposées sur le Site. AbbVie se réserve le droit de modifier ou faire modifier, de supprimer ou faire supprimer, à tout moment et sans préavis, les informations présentes sur le Site.</p>
          <p>L’accès à certains espaces du Site est réservé aux professionnels de santé selon les conditions décrites dans les Conditions Spécifiques ci-après.</p>
          <p>Le fait d’accéder au Site et à ses informations, implique l’acceptation sans réserve de l’intégralité des Conditions Générales et Spécifiques.</p>
          <h2>I. Conditions Générales</h2>
          <p>1. AbbVie a développé ce Site dans des buts de communication et d’information.</p>
          <p>2. Les informations contenues dans le Site sont à l’usage des résidents en France. Les autres pays peuvent avoir une réglementation différente. Les pratiques médicales peuvent nécessiter des références différentes ou informations additionnelles. Par conséquent, les informations figurant sur ce Site peuvent ne pas être appropriées en dehors de la France.</p>
          <p>3. Toutes les informations contenues sur le Site ne constituent en aucune façon une offre de contracter, ni une offre d’achat ou de vente, sauf mention contraire. Ce Site n’a aucunement vocation à fournir, de quelque manière que ce soit, des conseils en matière de santé, ni des consultations dans ce domaine.</p>
          <p>4. AbbVie s’efforcera de présenter sur le Site des informations précises, fiables et régulièrement mises à jour. AbbVie s’efforce de contrôler la véracité des informations et de maintenir le Site à jour. De manière générale, les informations contenues sur le Site tendent à être scientifiquement exactes au moment de leurs publications, fiables, pertinentes et communes à tous les Utilisateurs.</p>
          <p>Cependant, aucune garantie n’est apportée concernant l’exactitude, la précision, la mise à jour ou l’exhaustivité des informations mises à disposition sur le Site. Compte tenu de l’évolution des connaissances scientifiques et médicales, des progrès de l’actualité médicale, les Utilisateurs du Site sont informés que les informations qui leur sont proposées, ne sont ni complètes, ni exhaustives et peuvent ne pas être immédiatement et systématiquement actualisées.</p>
          <p>Les caractéristiques des produits présentés, les textes et les photographies ne sont fournis qu’à titre indicatif et n’ont aucune valeur contractuelle. AbbVie ne pourra être tenue responsable à quel titre que ce soit en cas de dommage allégué ou avéré résultant, directement ou indirectement, de la consultation ou de l’utilisation de ces informations. Le Site peut contenir des informations médicales ou de santé publique. Ces informations ne sauraient en aucun cas être utilisées en remplacement de l’avis d’un médecin ou de tout autre spécialiste de la santé dans le cadre d’un diagnostic médical ou d’une prescription médicale.</p>
          <p>Le Site peut reproduire des opinions formulées par des experts et susceptibles d’intéresser les Utilisateurs. De tels avis ou opinions d’experts peuvent également être accessibles au moyen de liens hypertextes ou être directement transmis par tout autre moyen aux Utilisateurs en ayant fait expressément la demande par le biais d’un formulaire d’inscription. Ces informations désignées comme constituant des opinions d’experts indépendants ne doivent être regardées que comme l’expression des opinions des experts dont elles émanent. De telles informations représentent uniquement l’opinion de l’expert consulté, et pas nécessairement l’opinion d’AbbVie. Les experts susvisés ne sont pas des salariés d’AbbVie. Si de telles informations venaient à se révéler inexactes ou incomplètes, ou porter atteinte de quelle manière que ce soit aux droits des Utilisateurs ou de tiers, la responsabilité d’AbbVie ne saurait être engagée sur quelque fondement que ce soit.</p>
          <p>5. L’Utilisateur reconnaît que l’ensemble du contenu disponible sur le Site, incluant de manière non limitative, les textes, les graphiques, photographies, dessins, sons, données, images, audio et vidéo, mais également l’arborescence du Site, son plan de navigation, ses logos, la conception et l’organisation de ses rubriques, leurs titres, les bases de données, leur structure et leurs contenus, les marques (ci-après désigné le« Contenu ») appartient exclusivement à AbbVie ou à ses partenaires.</p>
          <p>Le Contenu ne peut être utilisé par l’Utilisateur que pour son information personnelle et dans la stricte limite de l’utilisation du Site dans le respect des présentes Conditions générales. L’Utilisateur peut reproduire des extraits sélectionnés du Contenu à des fins strictement personnelles sous réserve de maintenir et de ne pas modifier les avis et mentions relatifs à la propriété intellectuelle figurant sur les rubriques du Site où le Contenu est accessible. Toute autre forme d’utilisation, sans l’autorisation préalable et expresse d’AbbVie, telle que notamment les reproductions, extractions, le téléchargement des vidéos, le copier/coller des articles, les altérations, modifications, distributions, transmissions, re-publications, expositions, diffusions ou représentations publiques du Contenu dans tout autre but est strictement illicite conformément aux dispositions du Code de la propriété intellectuelle et constituerait une contrefaçon sanctionnée notamment par les articles L.335-2 et suivants du Code de la propriété intellectuelle français susceptible d’exposer les auteurs de ces agissements à des poursuites judiciaires civiles et pénales.</p>
          <p>L’Utilisateur s’engage à utiliser le Site : conformément à sa destination définie aux présentes Conditions Générales et Spécifiques, pour un usage personnel à l’exclusion de toute activité commerciale ou promotionnelle,- dans le respect des droits d’auteur, sans mettre en œuvre de robot ou tout autre moyen automatisé pour accéder et utiliser les Contenus, sans tenter de le copier, de le reproduire en tout ou partie, de le rendre accessible ou de le diffuser par quelque moyen que ce soit à des tiers non autorisés.</p> 
          <p>6. Tout Utilisateur du Site s’engage à respecter sans réserve l’ensemble de la législation applicable, et plus particulièrement :</p>
          <ul>
            <li>Le droit de la propriété intellectuelle et de la propriété industrielle ayant prises sur notamment les créations multimédias, les logiciels, les textes, les bases de données, les articles, les photos, les vidéos, les marques déposées, les bases de données, les images de toute nature auxquels les internautes ont accès sur le Site,</li>
            <li>Le droit à l’image des modèles figurant sur des photographies,</li>
            <li>Le droit des photographes et des agences sur ces photographies,</li>
            <li>Le droit de la presse au regard des qualifications de diffamation et d’injures,</li>
            <li>Le dispositif légal de protection contre la fraude informatique ainsi que les dispositions en matière de confiance dans l’économie numérique. • En conséquence, tout Utilisateur s’interdit notamment :</li>
            <li>de transmettre des virus, un cheval de Troie, des bombes logiques ou tout autre programme nuisible ou destructeur,</li>
            <li>d’entraver le fonctionnement du Site,</li>
            <li>de reprendre tout ou partie du contenu d’œuvres préexistantes sans l’autorisation des titulaires des droits sur ces œuvres, • de reproduire et/ou utiliser la marque, la dénomination sociale, le logo ou tout signe distinctif et de manière générale toute donnée de quelque nature qu’elle soit, rédactionnel, graphique ou autre d’AbbVie,</li>
            <li>de procéder ou même de tenter une intrusion au sein du Site ou du système d’information d’administration du Site ou de modifier, totalement ou partiellement les éléments qui y sont contenus,</li>
            <li>de commercialiser tout ou partie des informations, services ou tous autres contenus proposés par le Site.</li>
          </ul>
          <p>7. L’accès au Site et au contenu du Site et l’utilisation des informations présentées sur le Site s’effectuent aux seuls risques de l’Utilisateur. AbbVie décline toute responsabilité pour les dommages qui pourraient intervenir sur l’équipement matériel et logiciel de l’Utilisateur en raison de l’accès au Site, de l’utilisation ou de la copie des informations présentées sur le Site (incluant les textes, données, graphismes, logos, animations, vidéos et sons).</p>
          <p>8. Afin d’apporter un complément d’information, le présent Site peut contenir des liens vers ou provenant d’autres sites web. La responsabilité d’AbbVie ne saurait être engagée au titre d’un site tiers auquel vous auriez accès via notre Site. AbbVie ne dispose d’aucun moyen de contrôle du contenu de ces sites tiers.</p>
          <p>En tout état de cause, AbbVie n’est pas responsable de l’indisponibilité des sites, de leur contenu, publicité ou autres éléments disponibles sur des sites tiers. Des sites externes peuvent contenir des liens hypertextes pointant vers notre Site. Un tel lien ne pourra être installé sans l’accord préalable et écrit d’AbbVie. Les liens “in-line” ou tout autre procédé visant à intégrer des parties de ce Site sur des sites de tiers sont également interdits.</p>
          <p>AbbVie n’ayant ni le contrôle, ni la propriété des sites auxquels le Site AbbVie est lié, et n’ayant pu s’assurer du contenu de ces sites, vous reconnaissez qu’AbbVie n’est pas responsable du contenu des pages ” off-Site “, ni des autres sites auxquels le Site AbbVie est lié. Toute liaison avec le Site AbbVie, avec les pages ” off-Site ” ou avec les autres sites, s’effectue sous votre seule responsabilité et sans l’autorisation d’AbbVie.</p>
          <p>Il est recommandé à l’Utilisateur de contrôler les conditions générales d’utilisation et de confidentialité de ces espace personnels et sites, et de vérifier la conformité médicale, scientifique ou encore l’actualité, la complétude et la conformité de ces espace personnels à ses besoins.</p>
          <p>9. Protection des données à caractère personnel Vous pouvez à tout moment consulter notre notice d’information professionnels de santé <a onClick={(e) => { e.preventDefault(); ModalService.open(ModalLeaveSite, { url: "https://privacy.abbvie/privacy-policies/france-privacy-policy.html" }); }} href="https://privacy.abbvie/privacy-policies/france-privacy-policy.html" target="_blank" rel="noreferrer noopener">https://privacy.abbvie/privacy-policies/france-privacy-policy.html</a></p>
          <p>10. Il est techniquement impossible de fournir le Site exempt de tout défaut et qu’AbbVie ne peut s’y engager; des défauts peuvent conduire à l’indisponibilité temporaire du Site; et le fonctionnement du Site peut être affecté par des événements et/ou des éléments qu’AbbVie ne contrôle pas tels que les moyens de communication entre vous et AbbVie disponibles sur le Site.</p>
          <p>AbbVie et/ou ses prestataires pourra(ont), à tout moment, modifier ou interrompre, temporairement ou de façon permanente, tout ou partie du Site pour effectuer des opérations de maintenance et/ou effectuer des améliorations et/ou des modifications sur le Site. AbbVie n’est pas responsable des conséquences de toute modification, suspension ou interruption du Site notamment sur les plateformes qui ne sont pas la propriété d’AbbVie. L’Utilisateur reconnaît avoir connaissance des contraintes et limites du réseau internet. Dans ces conditions :</p>
          <ul>
            <li>Il incombe à l’Utilisateur, de protéger ses équipements techniques notamment contre toute forme de contamination par des virus et/ou de tentative d’intrusion, ou d’accès/utilisation par des tiers, y compris proches ou parents de l’Utilisateur, AbbVie ne pouvant en aucun cas en être tenu pour responsable à ce titre.</li>
            <li>AbbVie ne sauraient être tenu responsable des dommages subis par l’Utilisateur par le fait d’une panne, interruption ou erreur, évolution, remise en état, contrôle, maintenance, problème technique, coupure du réseau de télécommunications ou des réseaux, surcharge, négligence ou faute de tiers ou de l’Utilisateur, ainsi qu’en cas d’évènements indépendants de la volonté d‘AbbVie.</li>
            <li>AbbVie fera ses meilleurs efforts pour rendre le Site accessible 24 heures sur 24 et 7 jours sur 7, mais ne saurait en aucun cas engager sa responsabilité à ce titre.</li>
          </ul>
          <p>11. Les présentes Conditions Générales et Spécifiques et votre utilisation du Site seront régies par la loi française. Tout litige relatif au Site ou en relation avec son utilisation sera soumis, à défaut de règlement amiable, aux tribunaux français.</p>
          <p>12. Si l’une des dispositions des Conditions Générales et Spécifiques s’avérait illégale, nulle ou inopposable pour quelque raison que ce soit, cette disposition sera considérée comme indépendante des autres dispositions des présentes et sa nullité, son caractère illégal ou son inopposabilité n’affectera en rien la validité et l’opposabilité des dispositions restantes.</p>
          <p>AbbVie peut céder les présentes Conditions Générales et Spécifiques à tout moment à une filiale ou à un successeur, quelle que soit l’opération.</p>
          <h2>II. Conditions Spécifiques</h2>
          <p>Accès réservé aux Professionnels de santé</p>
          <p>L’accès à certains espaces du Site est réservé aux professionnels de santé (et éventuellement les autorités réglementaires ou judiciaires) auxquels sont attribués un identifiant et un mot de passe personnel pour accéder au Site, sauf conditions particulières, après avoir vérifié leur qualité de Professionnel de santé. Afin de faciliter et sécuriser l’accès à la partie du Site réservé aux professionnels de santé, les données fournies volontairement par l’Utilisateur dans ce cadre sont vérifiées soit automatiquement, soit manuellement sur des fichiers recensant les professionnels de santé, afin de s’assurer de sa qualité de Professionnel de santé. AbbVie se réserve le droit de refuser, sans justification aucune, l’accès au Site à tout Utilisateur en faisant la demande. De même, AbbVie se réserve le droit d’annuler tout identifiant et/ou mot de passe existant, sans en aviser l’Utilisateur et sans fournir de justificatif.</p>
        </MainWrapper>
      </section>
    </>
  );
};

export default LegalDisclaimer;
