import styles from "./Stories.module.scss";
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import Person from "../../assets/person_3.webp"
import PersonMobile from "../../assets/person_3_mobile.webp"
import CarouselThumbnail from "../../components/CarouselThumbnail/CarouselThumbnail";
import Message from "../../assets/Message";
import { Helmet } from "react-helmet";
import ModalService from '../../components/GlobalModal/ModalService';
import ModalLeaveSite from '../../components/ModalLeaveSite/ModalLeaveSite';

const Stories = (props) => {
  return (
    <>
      <Helmet>
        <title>About Hep C - Hear other peoples' hep C stories</title>
        <meta
          name="description"
          content="Hear other peoples' hep C stories"
        />
      </Helmet>
      <section className={styles.headerBanner}>
        <div className={styles.imageOverlay}>
          <MainWrapper>
            <div className={styles.position}>
              <img alt="person" src={Person} />
            </div>
          </MainWrapper>
        </div>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <img alt="person" className={styles.mobileHero} src={PersonMobile} />
              <div className={styles.brandBox}>
                <div className={styles.circle}>
                  <Message />
                </div>
                <h1>Ils racontent <span className={styles.blue}>leur histoire</span></h1>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div>
                <h4>En 2019, on estime à 100 600 le nombre de personnes porteuses chroniques du virus de l’hépatite&nbsp;C en France.</h4>
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.video}>
        <MainWrapper size="8">
          <p>Vous n'êtes pas seul(e). Découvrez les témoignages de personnes atteintes d’hépatite C.</p>
          <CarouselThumbnail
            type={props.type}
            location="stories"
          />
        </MainWrapper>
      </section>
      {/* <section className={styles.supportServices}>
        <MainWrapper size="8">
          <h2>Liens <span className={styles.blue}>utiles</span></h2>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3 className={styles.blue}>Associations</h3>
              <div className={styles.hotline}>
                <h3>ASUD</h3>
                <p><a href="http://www.asud.org/" target="_blank" rel="noreferrer">http://www.asud.org/</a></p>
              </div>
              <div className={styles.hotline}>
                <h3>Hépatites Info Service</h3>
                <p><a href="https://www.hepatites-info-service.org/" target="_blank" rel="noreferrer">https://www.hepatites-info-service.org/</a></p>
                <h3><a href="tel:0 800 84 58 00">0 800 84 58 00</a></h3>
                <p><em>Service et appel gratuit, 7 jours sur 7, de 8h à 23h</em></p>
              </div>
              <div className={styles.hotline}>
                <h3>SOS hépatites</h3>
                <p><a href="https://soshepatites.org/" target="_blank" rel="noreferrer">https://soshepatites.org/</a></p>
                <h3><a href="tel:0 800 004 372">0 800 004 372</a></h3>
                <p><em>Appel gratuit depuis un poste fixe ou un mobile </em></p>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <h3 className={styles.blue}>Société Savante</h3>
              <div className={styles.hotline}>
                <h3>AFEF</h3>
                <p>Société Française d'hépatologie</p>
                <p><a href="https://afef.asso.fr/hepatite-c/" target="_blank" rel="noreferrer">https://afef.asso.fr/hepatite-c/</a></p>
              </div>
            </div>
          </div>
        </MainWrapper>
      </section> */}
    </>
  );
};

export default Stories;
