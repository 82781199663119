import styles from "./AboutSymptoms.module.scss";
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import { Link, NavLink } from "react-router-dom";
import Location from "../../assets/Location";
import QuestionMark from "../../assets/QuestionMark";
import SignsAndSymptoms from "../../assets/SignsAndSymptoms";
import { Helmet } from "react-helmet";

const AboutSymptoms = (type) => {

  function appendSegment(type, value) {
    if (type.type === "seg-2") {
      return "seg2_3_" + value;
    } else {
      return "seg1_" + value;
    }
  }

  return (
    <>
      <Helmet>
        <title>About Hep C - Hep C signs and symptoms</title>
        <meta
          name="description"
          content="They're not always obvious"
        />
      </Helmet>
      <section className={styles.headerBanner}>
        <MainWrapper>
          <h1>A propos de <span className={styles.blue}>l'hépatite&nbsp;C</span></h1>
          <div className={styles.threeColumn}>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/">Hépatite C</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/symptoms">Symptômes</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/diagnosis">Diagnostic</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/prise-en-charge">Prise en charge</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/faqs">Questions</NavLink>
            </div>
          </div>
          <div className={styles.icon}>
            <SignsAndSymptoms />
          </div>
        </MainWrapper>
      </section>
      <section>
        <MainWrapper size="8">
          <h2><span className={styles.blue}>Symptômes de</span> l'hépatite&nbsp;C</h2>
          <p>Certaines personnes atteintes d'hépatite&nbsp;C peuvent ne pas ressentir de symptômes, il est possible d’être atteint du virus sans le savoir, en particulier au moment de l'infection et aux premiers stades de la maladie.<sup>(6)</sup></p>
          <p>Lorsque le virus de l'hépatite&nbsp;C se reproduit, il peut endommager les cellules hépatiques mais les personnes ne se sentent pas toujours malade.</p>
          <p>Lorsque les personnes se sentient malades, les personnes atteintes peuvent ressentir les signes suivants :</p>
          <ul className={`${styles.arrowList} ${styles.maxWidthUl}`}>
            <li>Syndrôme dépressif <sup>(7)</sup></li>
            <li>Trouble de l’humeur <sup>(7)</sup></li>
            <li>Fatigue durable <sup>(7)</sup></li>
            <li>Vomissements <sup>(6)</sup></li>
            <li>Douleurs musculaires <sup>(4)</sup></li>
            <li>Douleurs abdominales <sup>(4)</sup></li>
            <li>Douleurs articulaires <sup>(4)</sup></li>
            <li>Urine foncée <sup>(6)</sup></li>
            <li>La jaunisse (coloration jaune des yeux ou de la peau) <sup>(6)</sup></li>
          </ul>
          <p>Les hommes, les femmes, les enfants et les bébés présentent les mêmes symptômes.</p>
          <p>Un diagnostic précoce est essentiel pour prévenir d'autres problèmes de santé et peut être confirmé par un prélèvement sanguin. Au fil des années, l'hépatite&nbsp;C chronique peut entraîner des atteintes hépatiques graves, telles que des maladies hépatiques sévères, un cancer du foie ou une insuffisance hépatique. <sup>(5)</sup></p>
          <p>Toutefois, ces symptômes peuvent également être causés par d'autres problèmes de santé.  C’est pourquoi il est important de consulter un professionnel de  santé pour obtenir  plus d’informations.</p>
        </MainWrapper>
      </section>
      <section className={styles.understandingHepC}>
        <MainWrapper size={10}>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>

              <div className={styles.brandBox}>
                <div className={styles.circle}>
                  <SignsAndSymptoms />
                </div>
                <div className={styles.brandBoxContent}>
                  <h4>Diagnostic</h4>
                  <p>Découvrez comment l'hépatite&nbsp;C est diagnostiquée.</p>
                  <Link className={styles.blueButton} to="/about/diagnosis" id={appendSegment(type, "symptoms_button_diagnosis")}>En savoir plus</Link>
                </div>
              </div>

            </div>
            <div className={styles.rightColumn}>

              <div className={styles.brandBox}>
                <div className={styles.circle}>
                  <QuestionMark />
                </div>
                <div className={styles.brandBoxContent}>
                  <h4>Des questions ?</h4>
                  <p>Nous vous répondons.</p>
                  <Link className={styles.blueButton} to="/about/faqs" id={appendSegment(type, "symptoms_button_faqs")}>En savoir plus</Link>
                </div>
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.whereYouStand}>
        <MainWrapper>
          <h2>DÉSIREZ-VOUS <span className={styles.blue}>CONNAÎTRE VOTRE ÉTAT DE SANTÉ ?</span></h2>
          <div className={styles.getTestedToday}>
            <Location />
            <NavLink className={styles.blueButton} to="/doctor">Trouvez dès maintenant un centre de référence pour l’hépatite&nbsp;C</NavLink>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.references}>
        <MainWrapper>
          <p>Références :</p>
          <p>
            5. Remilieux M. Fibrose et hépatite chronique C : éducation thérapeutique <br/>
            6. OMS. Hépatite&nbsp;C<br/>
            7. Grignoli R. Morbidité et mortalité extra-hépatiques associées à l’infection par le virus de l’hépatite&nbsp;C.
          </p>
        </MainWrapper>
      </section>
    </>
  );
};

export default AboutSymptoms;
