import styles from "./GlobalModal.module.scss";

export default function Modal(props) {
  return (
    <div className={styles.modal}>
      <div className={styles.dialog}>
        <div className={styles.modalContent}>
          { props.children }
        </div>
      </div>
    </div>
  );
}