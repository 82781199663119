import './App.scss';
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import HomeAlt from './pages/HomeAlt/HomeAlt';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import About from './pages/About/About';
import Stories from './pages/Stories/Stories';
import Doctor from './pages/Doctor/Doctor';
import AboutSymptoms from './pages/AboutSymptoms/AboutSymptoms';
import AboutCare from './pages/AboutCare/AboutCare';
import AboutDiagnosis from './pages/AboutDiagnosis/AboutDiagnosis';
import AboutFaqs from './pages/AboutFaqs/AboutFaqs';
import Risk from './pages/Risk/Risk';
import UsefulLinks from './pages/UsefulLinks/UsefulLinks';
import LegalDisclaimer from './pages/LegalDisclaimer/LegalDisclaimer';
import ModalRoot from './components/GlobalModal/ModalRoot';
import { useState } from 'react';

function App() {
  const [count, setCount] = useState(0);

  const handleClick = num => {
    setCount(num);
  };

  return (
    <div className="App">
      <ScrollToTop />
      <Header type={count}/>
      <main>
        <Routes>
          <Route path="/" element={<Home handleClick={handleClick} />} />
          {/* <Route path="segment-1" element={<Navigate replace to="/" />} /> */}
          <Route path="segment-1" element={<Home handleClick={handleClick} type={count} />}  />
          <Route path="segment-2&3" element={<HomeAlt handleClick={handleClick} type={count} />}  />
          <Route path="about" element={<About type={count} />} />
          <Route path="about/symptoms" element={<AboutSymptoms type={count}  />} />
          <Route path="about/prise-en-charge" element={<AboutCare type={count}  />} />
          <Route path="about/diagnosis" element={<AboutDiagnosis type={count}  />} />
          <Route path="about/faqs" element={<AboutFaqs />} />
          <Route path="doctor" element={<Doctor type={count} />} />
          <Route path="stories" element={<Stories type={count} />} />
          <Route path="survey" element={<Risk type={count} />} />
          <Route path="liens-utiles" element={<UsefulLinks type={count} />} />
          <Route path="mentions-legales" element={<LegalDisclaimer type={count} />} />
        </Routes>
      </main>
      <ModalRoot />
      <Footer />
    </div>
  );
}

export default App;
