import { Link } from "react-router-dom";
import styles from "./Home.module.scss";
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import Person from "../../assets/person_1.webp"
import PersonMobile from "../../assets/person_1_mobile.webp"
import QuestionMark from "../../assets/QuestionMark";
import SignsAndSymptoms from "../../assets/SignsAndSymptoms";
import Carousel from "../../components/Carousel/Carousel";
import ModalService from '../../components/GlobalModal/ModalService';
import ModalSegmentA from '../../components/ModalSegmentA/ModalSegmentA';
import { useIdleTimer } from 'react-idle-timer'
import Convo from "../../assets/Convo";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const Home = (props) => {

  useEffect(() => {
    props.handleClick("seg-1");
  }, []);

  const onIdle = () => {
    console.log("idle");

    if (document.cookie.indexOf('homeModalSegmentA=true') < 0) {
      document.cookie = "homeModalSegmentA=true; expires=0; path=/;";
      console.log('show-modal');
      ModalService.open(ModalSegmentA);
    } else {
      console.log('modal-already-shown');
    }
  }

  const idleTimer = useIdleTimer({
    onIdle,
    timeout: 5000
  });

  return (
    <>
      <Helmet>
        <title>About Hep C - Auriez-vous déjà été exposé au virus sans le savoir ?</title>
        <meta
          name="description"
          content="Learn more about Hep C, symptoms and diagnosis"
        />
      </Helmet>
      <section className={styles.headerBanner}>
        <div className={styles.imageOverlay}>
          <MainWrapper>
            <div className={styles.position}>
              <img alt="person" src={Person} />
            </div>
          </MainWrapper>
        </div>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <img alt="person" className={styles.mobileHero} src={PersonMobile} />
              <div className={styles.brandBox}>
                <div className={styles.circle}>
                  <Convo />
                </div>
                <h1>Êtes-vous à risque d’être infecté par le virus de <br /> <span className={styles.blue}>l'hépatite&nbsp;C</span> ?</h1>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div>
                <h4>Auriez-vous déjà été exposé au virus sans le savoir ?</h4>
                <div className={styles.twoColumn}>
                  <div className={styles.leftColumn}>
                    <Link className={styles.blueButton} to="/survey" state={{ id: "maybe" }} id="home_seg1_button_maybe">Peut-être</Link>
                  </div>
                  <div className={styles.rightColumn}>
                    <Link className={styles.blueButton} to="/survey" state={{ id: "not-sure" }} id="home_seg1_button_not_sure"> Je ne suis pas sûr(e)</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.understandingHepC}>
        <MainWrapper size={10}>
          <h2>Comprendre <span className={styles.blue}>l'hépatite&nbsp;C</span></h2>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>

              <div className={styles.brandBox}>
                <div className={styles.circle}>
                  <SignsAndSymptoms />
                </div>
                <div className={styles.brandBoxContent}>
                  <h4>Signes et symptômes</h4>
                  <p>Ils ne sont pas toujours évidents</p>
                  <Link className={styles.blueButton} to="/about/symptoms" id="home_seg1_button_symptoms">En savoir plus</Link>
                </div>
              </div>

            </div>
            <div className={styles.rightColumn}>

              <div className={styles.brandBox}>
                <div className={styles.circle}>
                  <QuestionMark />
                </div>
                <div className={styles.brandBoxContent}>
                  <h4>Des questions ?</h4>
                  <p>Nous vous répondons.</p>
                  <Link className={styles.blueButton} to="/about/faqs" id="home_seg1_button_faqs">En savoir plus</Link>
                </div>
              </div>

            </div>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.hepCStories}>
        <MainWrapper>
          <h2>Ils racontent <span className={styles.blue}>leur histoire</span></h2>
          <p>Vous n'êtes pas seul(e). Découvrez les témoignages de personnes atteintes d’hépatite&nbsp;C.</p>
          <Carousel 
            type={props.type}
            location="hp" />
        </MainWrapper>
      </section>
    </>
  );
};

export default Home;
