import { Link } from "react-router-dom";
import Close from "../../assets/Close";
import Modal from "../GlobalModal/GlobalModal";
import styles from "./ModalSegmentB.module.scss";

export default function ModalSegmentB(props) {
  return (
    <Modal>
      <div className={styles.modalContainer}>
        <p><strong>What brings you here today?</strong></p>
        <div className={styles.buttonContainer}>
          <Link className={styles.blueButton} onClick={props.close} to="/stories" id="modal_seg2_button_diagnosed">I've been diagnosed with hep C</Link>
          <Link className={styles.blueButton} onClick={props.close} to="/survey" state={{ id: false }} id="modal_seg2_button_at_risk">I think I may be at risk</Link>
        </div>
      </div>
      <div className={styles.exit} onClick={props.close}>
        <Close />
      </div>
    </Modal>
  );
}