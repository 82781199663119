const MedicalFile = () => {
  return (
    <svg width="350" height="421" viewBox="0 0 350 421" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M348.6 98.36C347.8 96.15 346.48 94.17 344.75 92.58L257.25 5.08C255.55 3.53 253.6 2.29 251.48 1.4C249.43 0.52 247.23 0.05 245 0H17.5C12.86 0.01 8.41 1.85 5.12 5.13C1.83 8.41 0 12.87 0 17.51V402.51C0 407.15 1.84 411.6 5.12 414.89C8.4 418.18 12.85 420.01 17.5 420.01H332.5C337.14 420.01 341.59 418.17 344.88 414.89C348.17 411.61 350 407.16 350 402.51V105.01C349.97 102.72 349.5 100.46 348.6 98.36ZM289.98 87.51H262.5V59.86L289.98 87.51ZM315 385.01H35V35.01H227.5V105.01C227.5 109.65 229.34 114.1 232.62 117.39C235.9 120.68 240.35 122.51 245 122.51H315V385.01Z" fill="#b65e29" />
      <path d="M69.9998 122.51H87.4998V140.01C87.4998 146.26 90.8398 152.04 96.2498 155.17C101.66 158.3 108.34 158.29 113.75 155.17C119.16 152.05 122.5 146.27 122.5 140.01V122.51H140C146.25 122.51 152.03 119.17 155.16 113.76C158.29 108.35 158.28 101.67 155.16 96.2601C152.04 90.8501 146.26 87.5101 140 87.5101H122.5V70.0101C122.5 63.7601 119.16 57.9801 113.75 54.8501C108.34 51.7201 101.66 51.7301 96.2498 54.8501C90.8398 57.9701 87.4998 63.7501 87.4998 70.0101V87.5101H69.9998C63.7498 87.5101 57.9698 90.8501 54.8398 96.2601C51.7098 101.67 51.7198 108.35 54.8398 113.76C57.9598 119.17 63.7398 122.51 69.9998 122.51Z" fill="#b65e29" />
      <path d="M52.5 192.51C52.5 197.15 54.34 201.6 57.62 204.89C60.9 208.18 65.35 210.01 70 210.01H280C286.25 210.01 292.03 206.67 295.16 201.26C298.29 195.85 298.28 189.17 295.16 183.76C292.04 178.35 286.26 175.01 280 175.01H70C65.36 175.01 60.91 176.85 57.62 180.13C54.33 183.41 52.5 187.86 52.5 192.51Z" fill="#b65e29" />
      <path d="M245 227.51H105C98.7498 227.51 92.9698 230.85 89.8398 236.26C86.7098 241.67 86.7198 248.35 89.8398 253.76C92.9598 259.17 98.7398 262.51 105 262.51H245C251.25 262.51 257.03 259.17 260.16 253.76C263.29 248.35 263.28 241.67 260.16 236.26C257.04 230.85 251.26 227.51 245 227.51V227.51Z" fill="#b65e29" />
      <path d="M245 280.01H105C98.7498 280.01 92.9698 283.35 89.8398 288.76C86.7098 294.17 86.7198 300.85 89.8398 306.26C92.9598 311.67 98.7398 315.01 105 315.01H245C251.25 315.01 257.03 311.67 260.16 306.26C263.29 300.85 263.28 294.17 260.16 288.76C257.04 283.35 251.26 280.01 245 280.01V280.01Z" fill="#b65e29" />
      <path d="M175 332.51H105C98.7498 332.51 92.9698 335.85 89.8398 341.26C86.7098 346.67 86.7198 353.35 89.8398 358.76C92.9598 364.17 98.7398 367.51 105 367.51H175C181.25 367.51 187.03 364.17 190.16 358.76C193.29 353.35 193.28 346.67 190.16 341.26C187.04 335.85 181.26 332.51 175 332.51Z" fill="#b65e29" />
    </svg>
  );
};

export default MedicalFile;
