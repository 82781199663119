import Collapse from "rc-collapse/lib/Collapse";
import { Panel } from "rc-collapse";
import './Accordion.css';
import Chevron from "../../assets/Chevron";
import styles from "./Accordion.module.scss";
import AccordionArrow from "../../assets/AccordionArrow";

const Accordion = () => {
  return (
    <Collapse accordion={true}>
      <Panel header={<><div className={styles.accordionText}>Peut-on attraper l'hépatite&nbsp;C en embrassant quelqu’un, <span className={styles.blue}>en lui faisant un calin ou en partageant de la nourriture ?</span></div><AccordionArrow /></>} showArrow={false}>
        <p>Non, l'hépatite&nbsp;C est un virus transmis par le sang. Vous pouvez être contaminé(e)  uniquement lorsque votre sang entre en contact avec le sang d'une personne infectée. <sup>(1)</sup></p>
      </Panel>
      <Panel header={<><div className={styles.accordionText}>Je ne présente aucun symptôme. <span className={styles.blue}>Est-ce nécessaire de faire un test ?</span></div><AccordionArrow /></>} showArrow={false}>
        <p>Si vous avez été exposé(e) à un facteur à risque, oui. Si l'hépatite&nbsp;C chronique n'est pas traitée pendant de nombreuses années, cela peut avoir des conséquences graves, pouvant entraîner des maladies hépatiques, un cancer du foie et un décès prématuré. <sup>(5)</sup><sup>(11)</sup></p>
      </Panel>
      <Panel header={<><div className={styles.accordionText}>Est-il possible que l'hépatite&nbsp;C <span className={styles.blue}>disparaisse naturellement ?</span></div><AccordionArrow /></>} showArrow={false}>
        <p>Dans 30% des cas, l'hépatite&nbsp;C disparaîtra spontanément, mais vous aurez besoin d'un test sanguin pour le confirmer. <sup>(1)</sup> Les anticorps restent présent dans votre organisme car vous avez été en contact avec le virus, cela ne signifie pas que vous êtes malade mais que vous avez déjà rencontré le virus dans votre vie.</p>
      </Panel>
      <Panel header={<><div className={styles.accordionText}>Existe-t-il un vaccin <span className={styles.blue}>contre l’hépatite&nbsp;C ?</span></div><AccordionArrow /></>} showArrow={false}>
        <p>Non, il existe des vaccins pour l’hépatite A et B mais pas pour l’hépatite&nbsp;C. <sup>(6)</sup></p>
      </Panel>
      <Panel header={<><div className={styles.accordionText}>On m'a fait savoir que j'ai des anticorps de l'hépatite&nbsp;C. <span className={styles.blue}>Cela indique-t-il que je suis infecté(e) ?</span></div><AccordionArrow /></>} showArrow={false}>
        <p>Pas nécessairement. La presence d’anticorps de l’hépatite&nbsp;C signifie que vous avez été en contact avec le VHC. Cela ne permet pas de savoir si le virus a été éliminé ou non de l’organisme. Pour savoir si le virus est toujours présent dans le corps il faudra faire le dosage de la charge virale via un test PCR. <sup>(1)</sup></p>
      </Panel>
      <Panel header={<><div className={styles.accordionText}>J'ai eu une hépatite&nbsp;C auparavant. <span className={styles.blue}>Suis-je désormais immunisé(e) ?</span></div><AccordionArrow /></>} showArrow={false}>
        <p>Non, une infection antérieure ne procure pas d'immunité. Si vous avez été guéri(e) auparavant, vous pouvez toujours être réinfecté(e) si vous êtes exposé(e). (15)</p>
      </Panel>
      <Panel header={<><div className={styles.accordionText}>Est-ce que l'hépatite&nbsp;C <span className={styles.blue}>peut être traitée ?</span></div><AccordionArrow /></>} showArrow={false}>
        <p>Si vous savez ou pensez être atteint(e) de l'hépatite&nbsp;C, il est essentiel de consulter votre médecin pour obtenir  un diagnostic ainsi que des conseils. Le traitement de l'hépatite&nbsp;C vise à éliminer le virus de votre corps. Votre médecin effectuera un test pour confirmer cette guérison, et pour certaines personnes, un suivi régulier sera nécessaire. Votre médecin vous orientera vers la prise en charge la plus adaptée à votre situation. <sup>(1)</sup></p>
      </Panel>
    </Collapse>
  );
};

export default Accordion;
