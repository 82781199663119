const Exit = () => {
  return (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="29" cy="29" r="27" fill="white" stroke="#b65e29" strokeWidth="4" />
      <path fillRule="evenodd" clipRule="evenodd" d="M39.6827 18.1927H19.2551V39.8972H39.6827C40.6538 39.8972 41.4452 40.6775 41.4452 41.6576C41.4452 42.63 40.655 43.4168 39.6827 43.4168H17.4979C16.5386 43.4168 15.7544 42.6554 15.7357 41.6927C15.7348 41.6727 15.7344 41.6527 15.7344 41.6326V16.4334C15.7344 15.4611 16.5224 14.6731 17.4947 14.6731H17.4979H17.9947H39.6827C40.6538 14.6731 41.4452 15.4534 41.4452 16.4334C41.4452 17.4058 40.655 18.1927 39.6827 18.1927ZM21.4053 29.0323C21.4053 28.0602 22.1962 27.272 23.1613 27.272H37.1988L33.6136 23.6868C33.121 23.1952 33.121 22.3972 33.6136 21.9046C34.1052 21.413 34.9032 21.413 35.3948 21.9046L41.6315 28.1413C42.1231 28.6339 42.1231 29.4309 41.6315 29.9235V29.9234L35.3948 36.1602C34.9032 36.6517 34.1052 36.6517 33.6136 36.1602C33.121 35.6675 33.121 34.8695 33.6136 34.3779L37.1989 30.7927H23.1613C22.1905 30.7927 21.4053 30.0085 21.4053 29.0323Z" fill="#b65e29" />
    </svg>
  );
};

export default Exit;
