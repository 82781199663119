import styles from "./AboutFaqs.module.scss";
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import { NavLink } from "react-router-dom";
import Accordion from "../../components/Accordion/Accordion";
import QuestionMark from "../../assets/QuestionMark";
import { Helmet } from "react-helmet";
import ModalService from '../../components/GlobalModal/ModalService';
import ModalLeaveSite from '../../components/ModalLeaveSite/ModalLeaveSite';

const AboutFaqs = (props) => {
  return (
    <>
      <Helmet>
        <title>About Hep C - Understanding Hep C</title>
        <meta
          name="description"
          content="We answer your frequently asked questions"
        />
      </Helmet>
      <section className={styles.headerBanner}>
        <MainWrapper>
          <h1>A propos de <span className={styles.blue}>l'hépatite&nbsp;C</span></h1>
          <div className={styles.threeColumn}>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/">Hépatite C</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/symptoms">Symptômes</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/diagnosis">Diagnostic</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/prise-en-charge">Prise en charge</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/faqs">Questions</NavLink>
            </div>
          </div>
          <div className={styles.icon}>
            <QuestionMark />
          </div>
        </MainWrapper>
      </section>
      <section className={styles.faqs}>
        <MainWrapper>
          <h2>FAQs</h2>
          <Accordion />
        </MainWrapper>
      </section>
      <section className={styles.references}>
        <MainWrapper>
          <p>Références :</p>
          <p>
            1. Amelie. Comprendre l’hépatite&nbsp;C  <br/>
            5. Remilieux M. Fibrose et hépatite chronique C : éducation thérapeutique  <br/>
            6. OMS. Hépatite&nbsp;C <br/>
            11. SNFGE. Hépatite&nbsp;C. Janvier 2019 <br/>
            15. Santé publique France. Hépatite&nbsp;C
          </p>
        </MainWrapper>
      </section>
    </>
  );
};

export default AboutFaqs;
