const Convo = () => {
  return (
    <svg width="334" height="334" viewBox="0 0 334 334" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M166.855 0.0135106C128.201 -0.046131 90.726 13.3534 60.8584 37.9125C30.9909 62.4649 10.5781 96.6528 3.12946 134.618C-4.31923 172.577 1.64634 211.953 20.0131 245.995L0.785605 310.104C-0.902764 315.723 0.163226 321.813 3.66576 326.518C7.1683 331.23 12.6836 334 18.5499 334C20.3574 334 22.1584 333.735 23.8865 333.218L87.9385 313.974C117.846 330.123 151.971 336.744 185.738 332.953C219.512 329.162 251.32 315.14 276.917 292.768C302.514 270.389 320.669 240.727 328.958 207.739C337.248 174.757 335.281 140.019 323.31 108.184C311.346 76.3482 289.947 48.9329 261.986 29.5958C234.032 10.2652 200.841 -0.0593847 166.855 0.000256945V0.0135106ZM166.855 296.896C143.211 296.87 120.031 290.349 99.8366 278.043C95.3343 275.346 89.9116 274.676 84.8929 276.187L46.1994 287.804L57.8061 249.077C59.329 244.054 58.6536 238.62 55.9522 234.12C41.1608 209.561 34.9171 180.794 38.1946 152.305C41.472 123.823 54.0785 97.2227 74.0542 76.6663C94.0299 56.1098 120.243 42.7567 148.601 38.6878C176.959 34.6189 205.866 40.0728 230.801 54.188C255.736 68.3032 275.301 90.291 286.438 116.712C297.574 143.134 299.647 172.497 292.344 200.224C285.041 227.951 268.76 252.47 246.056 269.958C223.352 287.446 195.498 296.916 166.855 296.89V296.896Z" fill="#b65e29" />
      <path d="M185.5 222.857V148.571C185.5 143.643 183.551 138.921 180.082 135.439C176.613 131.957 171.902 130 167 130H148.5C141.893 130 135.781 133.542 132.478 139.286C129.174 145.03 129.174 152.113 132.478 157.857C135.781 163.601 141.893 167.143 148.5 167.143V241.429C148.5 246.357 150.449 251.079 153.918 254.561C157.387 258.043 162.097 260 167 260H185.5C192.107 260 198.219 256.458 201.522 250.714C204.826 244.97 204.826 237.887 201.522 232.143C198.219 226.399 192.107 222.857 185.5 222.857Z" fill="#b65e29" />
      <path d="M186 92.5C186 102.715 177.715 111 167.5 111C157.285 111 149 102.715 149 92.5C149 82.2854 157.285 74 167.5 74C177.715 74 186 82.2854 186 92.5Z" fill="#b65e29" />
    </svg>

  );
};

export default Convo;
