const Close = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21" cy="21" r="19" fill="white" stroke="#b65e29" strokeWidth="4" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.1421 23.8287L26.7988 29.4855L29.6272 26.657L23.9705 21.0003L29.6275 15.3433L26.7991 12.5148L21.1421 18.1719L15.4851 12.5149L12.6567 15.3433L18.3137 21.0003L12.657 26.657L15.4854 29.4854L21.1421 23.8287Z" fill="#b65e29" />
    </svg>
  );
};

export default Close;
