const QuestionMark = () => {
  return (
    <svg width="165" height="281" viewBox="0 0 165 281" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M164.28 84.73C164.32 98.8 160.11 112.55 152.21 124.19C144.31 135.83 133.09 144.83 120 150C114.09 152.36 109.02 156.44 105.46 161.71C101.9 166.99 100.01 173.21 100.05 179.57V210.37C100.05 216.62 96.71 222.4 91.3 225.53C85.89 228.66 79.21 228.65 73.8 225.53C68.39 222.41 65.05 216.63 65.05 210.37V179.57C65.02 166.22 69.01 153.18 76.51 142.12C84 131.07 94.64 122.53 107.05 117.62C113.62 115.04 119.26 110.54 123.23 104.7C127.19 98.86 129.3 91.96 129.28 84.9C129.64 72.91 125.55 61.22 117.8 52.07C110.04 42.92 99.17 36.98 87.28 35.38C74.11 34 60.98 38.33 51.23 47.28C41.4 56.25 35.87 68.98 36 82.28C36 88.53 32.66 94.31 27.25 97.44C21.84 100.57 15.16 100.56 9.75 97.44C4.34 94.31 0.999997 88.53 0.999997 82.28C0.849997 59.14 10.51 37.02 27.59 21.4C44.67 5.78999 67.57 -1.85001 90.6 0.379993C111.12 2.87999 129.99 12.88 143.59 28.45C157.19 44.02 164.55 64.06 164.27 84.74L164.28 84.73Z" fill="#b65e29" />
      <path d="M100.05 259.55C100.14 260.66 100.14 261.77 100.05 262.87C99.98 267.5 98.17 271.94 94.98 275.3L92.36 277.4C91.38 278.06 90.32 278.59 89.21 278.97L86.06 280.37H82.56C80.27 280.34 78.01 279.87 75.91 278.97C73.76 278.13 71.8 276.89 70.13 275.29C66.93 271.93 65.12 267.5 65.06 262.86C64.97 261.75 64.97 260.64 65.06 259.54C65.22 258.38 65.58 257.25 66.11 256.22C66.49 255.1 67.02 254.04 67.68 253.07C68.32 252.15 69.02 251.27 69.78 250.45C73.07 247.14 77.54 245.27 82.2 245.27C86.86 245.27 91.34 247.13 94.63 250.45C95.39 251.28 96.09 252.16 96.73 253.07C97.39 254.05 97.92 255.11 98.3 256.22C99.07 257.22 99.66 258.34 100.05 259.54V259.55Z" fill="#b65e29" />
    </svg>
  );
};

export default QuestionMark;
