const Exclamation = () => {
  return (
    <svg width="21" height="83" viewBox="0 0 21 83" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9882 67.945H9.96091C5.87891 67.945 2.57031 71.2536 2.57031 75.3356C2.57031 79.4176 5.87891 82.7262 9.96091 82.7262H10.9882C15.0741 82.7262 18.3788 79.4176 18.3788 75.3356C18.3827 71.2536 15.0741 67.945 10.9882 67.945Z" fill="#89C6D9" />
      <path d="M7.19531 64.062H13.7617C19.5508 64.062 20.957 10.48 20.957 10.48C20.957 4.6909 16.2656 0 10.48 0C4.69091 0 8.11094e-06 4.6914 8.11094e-06 10.48C-0.00389889 10.48 1.40231 64.062 7.19531 64.062V64.062Z" fill="#89C6D9" />
    </svg>
  );
};

export default Exclamation;
