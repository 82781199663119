import styles from "./Arrow.module.scss";

const Arrow = (props) => {
  return (
    <div className={`${props.left ? styles.arrowLeft : styles.arrowRight} ${styles.arrowContainer}`} onClick={props.onClick}>
      <svg
        width="468" height="946" viewBox="0 0 468 946" fill="none" xmlns="http://www.w3.org/2000/svg"
        className={`${styles.arrow}`}
      >
        {props.left && (
          <path fillRule="evenodd" clipRule="evenodd" d="M105.439 473L467.183 886.022L398.727 945.978L13.0351 505.614C-3.31585 486.945 -3.31539 459.054 13.0352 440.386L398.727 0.0216005L467.183 59.9784L105.439 473Z" fill="#b65e29" />
        )}
        {!props.left && (
          <path fillRule="evenodd" clipRule="evenodd" d="M362.516 473L0.772095 59.9784L69.2279 0.021637L454.92 440.386C471.271 459.055 471.27 486.946 454.92 505.614L69.2279 945.978L0.772095 886.022L362.516 473Z" fill="#b65e29" />
        )}
      </svg>
    </div>
  )
}

export default Arrow;
