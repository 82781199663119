import styles from "./Risk.module.scss";
import React, { useState, useEffect } from "react"
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import Person from "../../assets/person_4.webp"
import PersonMobile from "../../assets/person_4_mobile.webp"
import LocationPin from "../../assets/LocationPin";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ModalService from '../../components/GlobalModal/ModalService';
import ModalLeaveSite from '../../components/ModalLeaveSite/ModalLeaveSite';

const Risk = (type) => {
  const [doesRelate, doesRelateChange] = useState(false);
  const [doesNotRelate, doesNotRelateChange] = useState(false);

  const doesRelateSelect = () => {
    doesRelateChange(true);
    doesNotRelateChange(false);
  }

  const doesNotRelateSelect = () => {
    doesRelateChange(false);
    doesNotRelateChange(true);
  }

  const location = useLocation();

  useEffect(() => {
    document.cookie = "homeModalSegmentA=true; expires=0; path=/;";
    document.cookie = "homeModalSegmentB=true; expires=0; path=/;";
    console.log('ignore-modal');
  });
  
  function appendSegment(type, value) {
    if (type.type === "seg-2") {
      return "seg2_3_" + value;
    } else {
      return "seg1_" + value;
    }
  }

  return (
    <>
      <Helmet>
        <title>About Hep C - Take our risk factor survey </title>
        <meta
          name="description"
          content="Take our risk factor survey"
        />
      </Helmet>
      <section className={styles.headerBanner}>
        <div className={styles.imageOverlay}>
          <MainWrapper>
            <div className={styles.position}>
              <img alt="person" src={Person} />
            </div>
          </MainWrapper>
        </div>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <img alt="person" className={styles.mobileHero} src={PersonMobile} />
              <div className={styles.brandBox}>
                <div className={styles.circle}>
                  <LocationPin />
                </div>
                <h1>ÊTES-VOUS A RISQUE  <br /> <span className={styles.blue}>D’AVOIR CONTRACTÉ une hépatite&nbsp;C ?</span></h1>
              </div>
            </div>
            <div className={styles.rightColumn}></div>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.relate}>
        <MainWrapper>
          {/* <p>Because you answered <strong>{location.state && location.state.id === "maybe" ? "MAYBE" : location.state.id === "not-sure"  ? "I'M NOT SURE" : "I THINK I MAY BE AT RISK"}</strong>, have a look at the list of hep C risk factors below.</p> */}
          <p>Si vous avez répondu <strong>{location.state && location.state.id === "maybe" ? "PEUT-ÊTRE" : location.state.id === "not-sure"  ? "JE NE SUIS PAS SÛR(E)" : ""}</strong>, consultez la liste des facteurs de risque de l'hépatite&nbsp;C ci-dessous. <sup>(9)</sup></p>
          <div className={styles.boxGrey}>
            <div className={styles.twoColumn}>
              <div className={styles.leftColumn}>
                <ul>
                  <li>Avez-vous déjà eu des tatouages ou des piercings  ?</li>
                  <li>Avez-vous eu une transfusion sanguine avant 1992 ?</li>
                  <li>Avez-vous déjà utilisé de la  drogue par voie intraveineuse ou nasale (en partageant le matériel de préparation et d'injection, ou en partageant une paille) ?</li>
                  <li>Votre mère a ou a-t-elle eu une hépatite&nbsp;C ?</li>
                  <li>Savez-vous si l'un de vos partenaires sexuels a eu une hépatite&nbsp;C ?</li>
                  <li>Êtes-vous, ou avez-vous déjà été, travailleur(se) du sexe ?</li>
                  <li>Êtes-vous un homme ayant eu ou ayant des rapports sexuels avec d'autres hommes ?</li>
                </ul>
              </div>
              <div className={styles.rightColumn}>
                <ul>
                  <li>Avez-vous déjà été en prison ?</li>
                  <li>Combinez-vous votre pratique sexuelle avec la prise de drogue ?</li>
                  <li>Êtes-vous originaire ou avez-vous séjourné plusieurs années ou  reçu des soins médicaux ou dentaires dans des pays à forte prévalence de l'hépatite&nbsp;C ?</li>
                  <li>Êtes-vous séropositif(ve) pour le VIH ou porteur(se) de l'hépatite B ?</li>
                  <li>Êtes-vous un professionnel de santé susceptible d'avoir eu un accident d'exposition au sang ?</li>
                  <li>Un médecin vous a-t-il déjà informé que certains de vos tests sanguins pourraient suggérer une maladie du foie ou des changements hépatiques ?</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.boxDarkGrey}>
            <h4>Si vous séléctionnez  <strong>"Oui"</strong> ou <strong>"Non"</strong></h4>
            <div className={styles.buttonGroup}>
              <div className={styles.surveyRow}>
                <div
                  className={doesRelate ? [styles.button, styles.buttonBlue].join(' ') : [styles.button, styles.buttonWhite].join(' ')}
                  onClick={doesRelateSelect}
                  id={appendSegment(type, "risk_button_yes")}>
                  <h4 id={appendSegment(type, "risk_button_yes")}>Oui</h4>
                </div>
                <p>- un ou plusieurs de ces éléments me concernent.</p>
              </div>
              <div className={styles.surveyRow}>
                <div
                  className={doesNotRelate ? [styles.button, styles.buttonBlue].join(' ') : [styles.button, styles.buttonWhite].join(' ')}
                  onClick={doesNotRelateSelect}
                  id={appendSegment(type, "risk_button_no")}>
                  <h4 id={appendSegment(type, "risk_button_no")}>Non</h4>
                </div>
                <p>- aucun de ces éléments ne me concerne.</p>
              </div>
            </div>
          </div>
          <div className={styles.answerYes}>
            <p>Si vous répondez <strong>OUI</strong> à l'une de ces questions, il est important de consulter un médecin ou de se diriger vers un centre afin de réaliser un tet de dépistage de l’hépatite&nbsp;C.</p>
            <p>Pour obtenir plus d'informations sur l'hépatite&nbsp;C, veuillez consulter un médecin. <Link to="/doctor">Cliquez ici pour trouver un centre …</Link></p>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.references}>
        <MainWrapper>
          <p>Références :</p>
          <p>
            9. HAS. Hépatite&nbsp;C : prise en charge simplifiée chez l’adulte. Fiche mémo
          </p>
        </MainWrapper>
      </section>
    </>
  );
};

export default Risk;
