import styles from "./About.module.scss";
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import { Link, NavLink } from "react-router-dom";
import Convo from "../../assets/Convo";
import { Helmet } from "react-helmet";
import ModalService from '../../components/GlobalModal/ModalService';
import ModalLeaveSite from '../../components/ModalLeaveSite/ModalLeaveSite';

const About = (type) => {

  function appendSegment(type, value) {
    if (type.type === "seg-2") {
      return "seg2_3_" + value;
    } else {
      return "seg1_" + value;
    }
  }

  return (
    <>
      <Helmet>
        <title>About hep C - Symptoms, diagnosis and FAQs</title>
        <meta
          name="description"
          content="Symptoms, diagnosis and FAQs"
        />
      </Helmet>
      <section className={styles.headerBanner}>
        <MainWrapper>
          <h1><span className={styles.blue}>L'hépatite&nbsp;C</span>, c'est quoi ?</h1>
          <div className={styles.threeColumn}>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/">Hépatite C</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/symptoms">Symptômes</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/diagnosis">Diagnostic</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/prise-en-charge">Prise en charge</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/faqs">Questions</NavLink>
            </div>
          </div>
          <div className={styles.icon}>
            <Convo />
          </div>
        </MainWrapper>
      </section>
      <section className={styles.whatisHep}>
        <MainWrapper size="8">
          <h2>Qu’est-ce que <span className={styles.blue}>l’hépatite&nbsp;C ?</span></h2>
          <p>L’hépatite&nbsp;C est une maladie due au virus de l’hépatite&nbsp;C ou VHC qui entraîne une inflammation du foie. <sup>(1)</sup></p>
          <p>Le virus de l’hépatite&nbsp;C est transporté par le sang jusqu’au foie, où il va se multiplier et perturber son fonctionnement.<sup>(2)</sup></p>
          <p>L’hépatite&nbsp;C chronique signifie que le virus reste présent dans le foie si vous n’êtes pas traité. Il est possible que vous ne vous sentiez pas malade, mais plus les années passent et plus le virus reste longtemps dans le corps, plus il y a de chance que cela impact votre foie.<sup>(3)</sup></p>
        </MainWrapper>
      </section>
      <section className={styles.howHepC}>
        <MainWrapper>
          <h2>Comment <span className={styles.blue}>attrape-t-on l’hépatite&nbsp;C</span> ?</h2>
          <p>Vous pouvez attraper l’hépatite&nbsp;C lorsque vous entrer en contact avec le sang d’une personne infectée. <sup>(1)</sup></p>
          <p>Cela a pu arriver récemment mais également dans votre passé.</p>
          <div className={`${styles.twoColumn} ${styles.comparisonBoxes}`}>
            <div className={styles.leftColumn}>
              <div className={styles.callout}>
                <div className={styles.calloutHeading}><h4>Vous pouvez attraper l’hépatite&nbsp;C en</h4></div>
                <div className={styles.calloutContent}>
                  <ul className={styles.arrowList}>
                    <li>Partageant des aiguilles, seringues, cuillères, pipes ou tout autre équipement de consommation de drogue.</li>
                    <li>Faisant un tatouage ou piercing réalisé avec du matériel non stérile.</li>
                    <li>Réalisant des soins médicaux avec du matériel contaminé, à l’étranger notamment</li>
                    <li>Ayant reçu des transfusions sanguines en France avant 1992</li>
                    <li>Partageant des objets de toilette, rasoirs, coupes-ongles, pince à épiler, brosse à dents …</li>
                    <li>Ayant des relations sexuelles pouvant entrainer des blessures, en présence de lésions ou pendant les règles.</li>
                    <li>Ayant une mère atteinte de l’hépatite&nbsp;C lors de la naissance. Risque de transmission de la mère à l’enfant de 5%.</li>
                  </ul>
                  <div className={styles.alert}>
                    <p>Si vous avez été exposé à une de ces situations, vous devriez consulter un médecin pour plus d’information.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={`${styles.callout} ${styles.grey}`}>
                <div className={styles.calloutHeading}><h4>Vous ne pouvez pas attraper l’hépatite&nbsp;C en</h4></div>
                <div className={styles.calloutContent}>
                  <ul className={`${styles.arrowList} ${styles.grey}`}>
                    <li>Partageant la nourriture avec quelqu’un</li>
                    <li>Mangeant de la nourriture préparée par quelqu’un qui a l’hépatite&nbsp;C</li>
                    <li>Vous faisant piquer ou mordre par un animal/insecte</li>
                    <li>Partageant les toilettes ou la douche</li>
                    <li>Toussant ou éternuant</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.whatHappens}>
        <MainWrapper size="8">
          <h2>Que se passe-t-il lorsque vous attrapez <span className={styles.blue}>l’hépatite&nbsp;C</span> ?</h2>
          <p>Si vous êtes atteint d’une hépatite&nbsp;C, votre système immunitaire tentera de combattre le virus. La maladie peut alors évoluer de 2 façons différentes : vous pouvez alors être atteint d’une hépatite aigue ou d’une hépatite&nbsp;Chronique <sup>(1)</sup></p>
          <p>L’hépatite&nbsp;C aiguë, a lieu les six premiers mois de l’infection, est le plus souvent asymptomatique et évolue en silence. Dans 30% des cas elle évoluera vers une guérison spontanée : le virus est éliminé de votre corps et vous n’avez pas besoin de traitement pour guérir.</p>
          <p>Dans 70% des cas, cette hépatite&nbsp;C aiguë évolue en hépatite&nbsp;C chronique. Cela signifie que le virus n’a pas été éliminé de votre corps.</p>
          <p>L’hépatite&nbsp;C est une maladie grave qui peut entrainer des complications au niveau du foie mais aussi sur le reste de votre corps. Alors, si vous pensez avoir un risque d’exposition passée ou présente à ce virus, parlez-en à votre médecin. Des tests simples et sans douleurs existent pour diagnostiquer l’hépatite&nbsp;C. <sup>(5)</sup></p>
        </MainWrapper>
      </section>
      <section className={styles.longtermInfection}>
        <MainWrapper size="8">
          <h2>Que peut-on faire pour traiter <span className={styles.blue}>l’hépatite&nbsp;C</span> ?</h2>
          <p>Le traitement de l’hépatite&nbsp;C chronique a pour objectif de débarrasser le corps du virus.</p>
          <p>Aujourd’hui, il existe des traitements antiviraux à action directe qui guérissent l’hépatite&nbsp;C dans plus de 95% des cas.</p>
          <p>Les effets à long terme de l’hépatite&nbsp;C chronique sur votre santé peuvent être influencés par :</p>
          <ul className={`${styles.arrowList} ${styles.maxWidthUl}`}>
            <li>L’âge auquel vous avez attrapé l’hépatite&nbsp;C</li>
            <li>La quantité d’alcool que vous consommez</li>
            <li>Une éventuelle autre infection virale (VHC, VIH, … )</li>
          </ul>
          <p>Le fait d'être atteint de l'hépatite&nbsp;C et d'une autre infection virale (comme le VIH ou l'hépatite B) peut vous exposer à un risque accru de maladie grave à long terme. Si vous avez le VIH ou l'hépatite B en plus de l'hépatite&nbsp;C, vous devez consulter régulièrement votre médecin.</p>
          <div className={`${styles.twoColumn}`}>
            <div className={styles.leftColumn}>
              <Link className={styles.blueButton} to="/about/symptoms" id={appendSegment(type, "about_button_symptoms")}>En savoir plus sur les symptômes</Link>
            </div>
            <div className={styles.rightColumn}>
              <Link className={styles.blueButton} to="/about/diagnosis" id={appendSegment(type, "about_button_testing")}>En savoir plus sur le diagnostic</Link>
            </div>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.references}>
        <MainWrapper>
          <p>Références :</p>
          <p>
            1. Amelie. Comprendre l’hépatite&nbsp;C <br />
            2. Fédération SOS hépatites. Qu’est-ce que l’hépatite&nbsp;C ? <br />
            3. Voir pour une réf <br />
            4. Amelie . Les symptômes, le diagnostic et l’évolution de l’hépatite&nbsp;C <br />
            5. Remilieux M. Fibrose et hépatite chronique C : éducation thérapeutique&nbsp;C : éducation thérapeutique 
          </p>
        </MainWrapper>
      </section>
    </>
  );
};

export default About;
