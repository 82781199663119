import { Link } from "react-router-dom";
import Close from "../../assets/Close";
import Modal from "../GlobalModal/GlobalModal";
import styles from "./ModalSegmentA.module.scss";

export default function ModalSegmentA(props) {
  return (
    <Modal>
      <div className={styles.modalContainer}>
        <p><strong>Y a-t-il un risque que vous ayez été exposé au virus de l’hépatite&nbsp;C ?</strong></p>
        <div className={styles.buttonContainer}>
          <Link className={styles.blueButton} onClick={props.close} to="/survey" state={{ id: false }} id="modal_seg1_maybe">Peut-être</Link>
          <Link className={styles.blueButton} onClick={props.close} to="/survey" state={{ id: true }} id="modal_seg1_button_not_sure">Je ne suis pas sûre</Link>
        </div>
      </div>
      <div className={styles.exit} onClick={props.close}>
        <Close />
      </div>
    </Modal>
  );
}