import styles from "./CarouselSlide.module.scss";
import { useState } from "react";
import ReactPlayerLoader from '@brightcove/react-player-loader';
import Close from "../../assets/Close";
import PlayButton from "../../assets/PlayButton";

const CarouselSlide = ({ slide, index, instanceRef, sliderLength, currentSlide, thumbnail, showLabel, conjoined, isVideo, updateOverlay, type, location }) => {
  const [enableFullscreen, setEnableFullscreen] = useState(false);

  const onSuccess = function ({ ref }) {
    const videoElement = ref.el().querySelector("video");
  };

  function fullScreenToggle(location) {
    if (!isVideo) {
      if (location !== "light" || !enableFullscreen) {
        if (enableFullscreen) {
          if (!thumbnail) {
            setEnableFullscreen(false);
            updateOverlay(false);
          }
        } else {
          if (!thumbnail) {
            setEnableFullscreen(true);
            updateOverlay(true);
          }
        }
      }
    }
  }

  function stateTracking(person) {
    // console.log(type);
    // console.log(type.location);
    // console.log(person);
    if (type.type === "seg-2") {
      if (type.location === "hp") {
        return "seg2_3_hpvideo_" + (person || '').toLowerCase();
      } else {
        return "seg2_3_storiesvideo_" + (person || '').toLowerCase();
      }
    } else {
      if (type.location === "hp") {
        return "seg1_hpvideo_" + (person || '').toLowerCase();
      } else {
        return "seg1_storiesvideo_" + (person || '').toLowerCase();
      }
    }
  }

  const shouldShow = (index) => {
    if (
      index === currentSlide ||
      index === currentSlide + 1 ||
      index === currentSlide - 1
    ) {
      return "";
    }

    if (currentSlide === sliderLength - 1 && index === 0) {
      return "";
    }

    if (currentSlide === 0 && index === sliderLength - 1) {
      return "";
    }

    return styles.hidden;
  };

  return (
    <div
      className={`${styles.slide} ${index === currentSlide ? styles.active : ""} ${thumbnail ? styles.thumbnail : ""} ${conjoined ? styles.conjoined : ""} ${instanceRef && shouldShow(index)} ${enableFullscreen && styles.fullscreen} keen-slider__slide`}
      key={index} >
      <div className={`${styles.inner} ${enableFullscreen && styles.fullscreen}`} onClick={() => fullScreenToggle("light")}>
        {enableFullscreen ?
          <>
            <div className={styles.playerContainer}>
              <ReactPlayerLoader
                key={currentSlide}
                accountId={slide.accountId}
                videoId={slide.videoId}
                attrs={{ className: styles.player }}
                onSuccess={onSuccess}
              />
              <div id={stateTracking(slide.name)} className={styles.close} onClick={() => fullScreenToggle("box")}>
                <Close />
              </div>
            </div>
          </>
          :
          <>
            <div className={styles.imageSizer}>
              {isVideo ?
                <>
                  <ReactPlayerLoader
                    key={currentSlide}
                    accountId={slide.accountId}
                    videoId={slide.videoId}
                    attrs={{ className: styles.player }}
                    onSuccess={onSuccess}
                  />
                </>
                :
                <>
                  <img src={slide.image} alt="slide" />
                  <div className={styles.playButton}>
                    <PlayButton />
                  </div>
                </>
              }
            </div>
          </>
        }
      </div>
      {showLabel ? <p>Meet {slide.name}</p> : ""}
    </div >
  );
};

export default CarouselSlide;
