import styles from "./AboutDiagnosis.module.scss";
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import FileA from "../../assets/high_risk_factors.webp"
import { NavLink } from "react-router-dom";
import Location from "../../assets/Location";
import ModalService from '../../components/GlobalModal/ModalService';
import ModalLeaveSite from '../../components/ModalLeaveSite/ModalLeaveSite';
import MedicalFile from "../../assets/MedicalFile";
import InfographieHvcTestSanguin from "../../assets/infographie-hvc_test-sanguin.webp"
import { Helmet } from "react-helmet";

const AboutDiagnosis = (type) => {

  function appendSegment(type, value) {
    if (type.type === "seg-2") {
      return "seg2_3_" + value;
    } else {
      return "seg1_" + value;
    }
  }

  return (
    <>
      <Helmet>
        <title>About Hep C - Testing and treating Hep C</title>
        <meta
          name="description"
          content="Learn more about Hep C, symptoms and diagnosis"
        />
      </Helmet>
      <section className={styles.headerBanner}>
        <MainWrapper>
          <h1>A propos de <span className={styles.blue}>l'hépatite&nbsp;C</span></h1>
          <div className={styles.threeColumn}>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/">Hépatite C</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/symptoms">Symptômes</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/diagnosis">Diagnostic</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/prise-en-charge">Prise en charge</NavLink>
            </div>
            <div className={styles.column}>
              <NavLink className={({ isActive }) => isActive ? [styles.active, styles.transparentButton].join(' ') : styles.transparentButton} to="/about/faqs">Questions</NavLink>
            </div>
          </div>
          <div className={styles.icon}>
            <MedicalFile />
          </div>
        </MainWrapper>
      </section>
      <section className={styles.testedforHepC}>
        <MainWrapper size="8">
          <h2>Comment se déroule <span className={styles.blue}>le dépistage de l’hépatite&nbsp;C ? <sup>(8)(9)</sup> </span></h2>
          <p>Si vous pensez que vous pouvez avoir été exposée à une hépatite&nbsp;C ou si l’un des facteurs de risques vous concerne, vous devriez parler du dépistage de l’hépatite&nbsp;C à un professionnel de santé.</p>
          <ul className={`${styles.arrowList} ${styles.maxWidthUl}`}>
            <li>Un dépistage de l’hépatite&nbsp;C implique deux tests : une recherche d’Anticorps et une analyse de la charge virale </li>
            <li>Le dépistage de l’hépatite&nbsp;C n’est pas inclus dans les tests standard, il doit être prescrit ou demandé</li>
          </ul>
          <table>
            <thead>
              <tr>
                <th>Test</th>
                <th>Ce que le test montre</th>
                <th>Ce que signifie un test positif</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Prélèvement sanguin (TROD ou prise de sang) = Recherche d’anticorps anti-VHC </td>
                <td>Il montre que vous avez eu un jour une hépatite&nbsp;C</td>
                <td>Vous avez été exposé au virus de l’hépatite&nbsp;C, cela peut-être récent ou antérieur*</td>
              </tr>
              <tr>
                <td>Test PCR = Analyse de la charge virale </td>
                <td>Il montre que vous êtes actuellement atteint d’une hépatite&nbsp;C</td>
                <td>Vous êtes atteints d’une hépatite&nbsp;C. Parlez à votre médecin des prochaines étapes.</td>
              </tr>
            </tbody>
          </table>
          <p className={styles.small}><em>* Toute personne ayant eu une hépatite&nbsp;C aura des anticorps le reste de sa vie, même si l’infection n’est pas présente</em><br/><br/></p>
          <img src={InfographieHvcTestSanguin} alt="Infographie HCV test sanguin"></img>
        </MainWrapper>
      </section>
      <section className={styles.infoOnTesting}>
        <MainWrapper size="8">
          <h2>Où se faire <span className={styles.blue}>dépister ?</span></h2>
          <div className={styles.document}>
            <p>
              <a href="/doctor">Trouvez dès maintenant un centre de référence pour l’hépatite C</a>
            </p>
          </div>
        </MainWrapper>
      </section>
      {/* <section className={styles.whereYouStand}>
        <MainWrapper>
          <h2>Want to know <span className={styles.blue}>where you stand?</span></h2>
          <div className={styles.getTestedToday}>
            <Location />
            <NavLink className={styles.blueButton} to="/doctor">Find a Hep C doctor today</NavLink>
          </div>
        </MainWrapper>
      </section> */}
      <section className={styles.references}>
        <MainWrapper>
          <p>Références :</p>
          <p>
            8. Ameli. Dépistage de l’hépatite&nbsp;C <br/>
            9. HAS. Hépatite&nbsp;C : prise en charge simplifiée chez l’adulte. Fiche mémo
          </p>
        </MainWrapper>
      </section>
    </>
  );
};

export default AboutDiagnosis;
