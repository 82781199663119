const PlayButton = () => {
  return (
    <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="70" cy="70" r="70" fill="white" fillOpacity="0.5" />
      <path d="M106.333 67.1243C108.556 68.4024 108.556 71.5976 106.333 72.8757L53 103.55C50.7778 104.828 48 103.231 48 100.674L48 39.3256C48 36.7694 50.7778 35.1717 53 36.4498L106.333 67.1243Z" fill="white" fillOpacity="0.9" />
    </svg>
  );
};

export default PlayButton;
