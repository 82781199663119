import styles from "./UsefulLinks.module.scss";
import React, { useState, useEffect } from "react"
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import Person from "../../assets/person_4.webp"
import PersonMobile from "../../assets/person_4_mobile.webp"
import LocationPin from "../../assets/LocationPin";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ModalService from '../../components/GlobalModal/ModalService';
import ModalLeaveSite from '../../components/ModalLeaveSite/ModalLeaveSite';

const UsefulLinks = (type) => {
  const [doesRelate, doesRelateChange] = useState(false);
  const [doesNotRelate, doesNotRelateChange] = useState(false);

  const doesRelateSelect = () => {
    doesRelateChange(true);
    doesNotRelateChange(false);
  }

  const doesNotRelateSelect = () => {
    doesRelateChange(false);
    doesNotRelateChange(true);
  }

  const location = useLocation();

  useEffect(() => {
    document.cookie = "homeModalSegmentA=true; expires=0; path=/;";
    document.cookie = "homeModalSegmentB=true; expires=0; path=/;";
    console.log('ignore-modal');
  });
  
  function appendSegment(type, value) {
    if (type.type === "seg-2") {
      return "seg2_3_" + value;
    } else {
      return "seg1_" + value;
    }
  }

  return (
    <>
      <Helmet>
        <title>About Hep C - Liens utiles </title>
        <meta
          name="description"
          content="Liens utiles"
        />
      </Helmet>
      <section className={styles.headerBanner}>
        <div className={styles.imageOverlay}>
          <MainWrapper>
            <div className={styles.position}>
              <img alt="person" src={Person} />
            </div>
          </MainWrapper>
        </div>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <img alt="person" className={styles.mobileHero} src={PersonMobile} />
              <div className={styles.brandBox}>
                <div className={styles.circle}>
                  <LocationPin />
                </div>
                <h1>Liens utiles</h1>
              </div>
            </div>
            <div className={styles.rightColumn}></div>
          </div>
        </MainWrapper>
      </section>
      <section className={styles.supportServices}>
        <MainWrapper size="8">
          <h2>Liens <span className={styles.blue}>utiles</span></h2>
          <div className={styles.twoColumn}>
            <div className={styles.leftColumn}>
              <h3 className={styles.blue}>Associations</h3>
              <div className={styles.hotline}>
                <h3>ASUD</h3>
                <p><a href="http://www.asud.org/" target="_blank" rel="noreferrer">http://www.asud.org/</a></p>
              </div>
              <div className={styles.hotline}>
                <h3>Hépatites Info Service</h3>
                <p><a href="https://www.hepatites-info-service.org/" target="_blank" rel="noreferrer">https://www.hepatites-info-service.org/</a></p>
                <h3><a href="tel:0 800 84 58 00">0 800 84 58 00</a></h3>
                <p><em>Service et appel gratuit, 7 jours sur 7, de 8h à 23h</em></p>
              </div>
              <div className={styles.hotline}>
                <h3>SOS hépatites</h3>
                <p><a href="https://soshepatites.org/" target="_blank" rel="noreferrer">https://soshepatites.org/</a></p>
                <h3><a href="tel:0 800 004 372">0 800 004 372</a></h3>
                <p><em>Appel gratuit depuis un poste fixe ou un mobile </em></p>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <h3 className={styles.blue}>Société Savante</h3>
              <div className={styles.hotline}>
                <h3>AFEF</h3>
                <p>Société Française d'hépatologie</p>
                <p><a href="https://afef.asso.fr/hepatite-c/" target="_blank" rel="noreferrer">https://afef.asso.fr/hepatite-c/</a></p>
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>
    </>
  );
};

export default UsefulLinks;
